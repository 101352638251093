<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>gewünschtes Pensum</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="">
              <v-range-slider
                v-model="rangeWorkload"
                :max="max"
                :min="min"
                hide-details
                class="align-center"
                thumb-label="always"
                :thumb-size="24"
                @change="setEditMode()"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="rangeWorkload[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeWorkload, 0, $event)"
                  />
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="rangeWorkload[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeWorkload, 1, $event)"
                  />
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <div
            v-if="editMode"
            class="d-flex flex-wrap mt-4"
          >
            <v-btn
              class="ma-2"
              outlined
              color="green"
              small
              @click="setWantedWorkload()"
            >
              Speichern
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              small
              @click="cancelEditMode()"
            >
              Abbrechen
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12">
      <base-card>
        <v-card-title>effektives Pensum: {{ getTotalWorkload }}</v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="workload"
            item-key="title"
            class="elevation-1 table-one"
            multi-sort
            hide-default-footer
          >
            <template v-slot:item.title="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <v-icon
                    :class="[item.iconClass]"
                    v-text="item.icon"
                  />
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.title }}
                </p>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Sliders',
    },
    data () {
      return {
        min: 0,
        max: 40,
        rangeWorkload: [0, 0],
        currentRangeWorkload: [0, 0],
        editMode: false,
        workload: [],
        search: '',
        selected: [],
        headers: [
          {
            text: 'Klass',
            align: 'start',
            sortable: false,
            value: 'course',
          },
          { text: 'Ausbildung', value: 'formation' },
          { text: 'Pensum', value: 'workload' },
        ],
      }
    },
    computed: {
      getTotalWorkload() {
        let Li_TotalWorkload = 0;
        this.workload.forEach(workload_item => {
          Li_TotalWorkload += workload_item.workload;
        });
        
        return Li_TotalWorkload;
      },
      ...mapGetters(['getPlanningPeriodID']),
    },
    methods: {
      setWantedWorkload() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/wanted-workload`, {
          i_MinWorkload: this.rangeWorkload[0],
          i_MaxWorkload: this.rangeWorkload[1],
        })
          .then(response => {
            this.editMode=false; 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.currentRangeWorkload = this.rangeWorkload;
            this.requestSent = true
            this.responseMessage = 'Pensum gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getWantedWorkload() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/wanted-workload`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.rangeWorkload=[response.i_MinWorkload, response.i_MaxWorkload];
            this.currentRangeWorkload = this.rangeWorkload;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getEffectiveWorkload() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/effective-workload`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.workload = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setEditMode() {
        this.editMode = true;
      },
      cancelEditMode() {
        this.editMode = false;
        this.rangeWorkload = this.currentRangeWorkload;
      }
    },
    beforeMount() {
      this.getWantedWorkload();
      this.getEffectiveWorkload();
    },
  }
</script>
